<template>
  <Layout>
    <PageHeader :title="title" moda_title="External Games" ref="pageForm" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                      }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" placeholder="Buscar..." primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                <template #cell(logo)="data">
                  <b-img :src="data.item.logo ? data.item.logo : 'NOIMATOCHARGE'" height="80px" class="logo-img"
                    @error="handleError"></b-img>
                </template>
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown variant="link" no-caret class="position-absolute">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Editar</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="manualDisable(data.item)">
                          <i class="m-2 font-size-18" :class="{
                            ['uil-toggle-off']: data.item.manualDisable,
                            ['uil-toggle-on']: !data.item.manualDisable,
                          }" />
                          <span class="align-middle ml-5">
                            {{
                              data.item.manualDisable
                              ? "Activar"
                              : "Desactivar"
                            }}
                          </span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="remove(data.item._id)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">Eliminar</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PageForm from "./form.vue";
import Swal from "sweetalert2";



export default {
  components: { Layout, PageHeader, PageForm },
  data() {
    return {
      title: "External Games",
      userRole: "",
      userData: {},

      // filter page
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      currentPage: 1,
      typeform: {},

      // filter

      sortBy: 'desc',
      sortDesc: true,
      filterOn: ['name'],

      // DATA FOR TABLE
      tableData: [],
      fields: [
        {
          key: "name",
          label: this.$t("externalGames.table.name").toUpperCase()
        },
        {
          key: "logo",
          label: this.$t("externalGames.table.logo").toUpperCase()
        },
        {
          key: "manualDisable",
          label: this.$t("helpers.isActive").toUpperCase(),
          formatter: (value) => {
            return !value ? "ACTIVO" : "INACTIVO"
          }
        },
        {
          key: "actions",
          label: this.$t("actions.text").toUpperCase()
        },
      ]
    }
  },
  methods: {
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {};
      this.getData();
    },
    edit(item) {
      this.typeform = item;
      this.$refs.pageForm.modal = true;
    },
    handleError(e) {
      e.target.src = "https://via.placeholder.com/150";
    },
    async getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;
    },
    async getData() {
      try {
        const { data } = await this.$http.get("/external-games")
        this.tableData = data.games;
      } catch (error) {
        console.log('ERROR GETTING DATA', error);
      }
    },
    async remove(id) {

      const { isConfirmed } = await Swal.fire({
        title: "¿Estas seguro en eliminar este juego",
        icon: "info",
        showCancelButton: true
      })

      if (!isConfirmed) return

      try {
        const { data } = await this.$http.delete(`/external-games/${id}`);

        if (!data.ok) return Swal.fire("Error eliminando el juego", "", "info");

        Swal.fire("Juego eliminado", "", "success")
        this.getData()
      } catch (error) {
        console.log('ERROR DELETE GAME', error);
      }
    },
    async manualDisable({ manualDisable, _id }) {

      const action = manualDisable ? 'activar' : 'desactivar';

      const { isConfirmed } = await Swal.fire({
        title: `¿Estas seguro en ${action} el juego?`,
        icon: 'question',
        showCancelButton: true
      })

      if (!isConfirmed) return

      try {

        const { data } = await this.$http.put(`/external-games/${_id}/manual-disable`, {
          manualDisable: !manualDisable
        });

        if (!data.ok) return Swal.fire("Error actualizando el status del juego")

        Swal.fire("Status actualizado", "", "success")
        this.getData()
      } catch (error) {
        console.log('ERROR MANUAL DISABLE', error);
      }
    },
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getData();
    },
  },
  mounted() {
    this.getUserRole();
    this.getData()
  }
}
</script>

<style scoped></style>