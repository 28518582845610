<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("externalGames.table.name") }}</label>
            <vs-input v-model="typeform.name" placeholder="Nombre" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.name.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("externalGames.table.logo") }}</label>
            <vs-input v-model="typeform.logo" placeholder="https://logo.4.my.game/" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.logo.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.logo.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.logo.required">
                Este campo es requerido.
              </span>
            </div>
            <div v-if="typesubmit && $v.typeform.logo.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.logo.url">
                Este campo debe ser una url.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Product ID</label>
            <vs-input v-model="typeform.productId" placeholder="Ej: end2end" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.productId.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.productId.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.productId.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Launch Url</label>
            <vs-input v-model="typeform.launchURL" placeholder="https://launch.for.game/api" border class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.launchURL.$error,
              }" />
            <div v-if="typesubmit && $v.typeform.launchURL.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.launchURL.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Token del proveedor</label>
            <vs-input v-model="typeform.tokenClient" placeholder="Abdef-gh12-fg23" border class="shadow-lg" :class="{
              'is-invalid': typesubmit && $v.typeform.tokenClient.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.tokenClient.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.tokenClient.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { url, required } from 'vuelidate/lib/validators';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => { }
    }
  },
  validations: {
    typeform: {
      logo: {
        url,
        required
      },
      name: {
        required
      },
      productId: {
        required
      },
      launchURL: {
        url,
        required
      }
    }
  },
  data() {
    return {
      typesubmit: false,
    }
  },
  methods: {
    async save() {
      this.typesubmit = true;
      this.$v.$touch();
      if (this.$v.$invalid) return Swal.fire("Error. Verify the data", "", "error");

      if (this.typeform._id) return this.update()

      return this.create()
    },
    async update() {
      const { _id, ...rest } = this.typeform;
      try {
        const { data } = await this.$http.put(`/external-games/${_id}`, rest);

        if (!data.ok) return Swal.fire("Error updating", "", "info");

        Swal.fire("Juego actualizado", "", "success");
        this.$emit("closeModa")
      } catch (error) {
        console.log('ERROR UPDATE GAME', error);
      }
    },
    async create() {
      try {
        const { data } = await this.$http.post("/external-games", this.typeform);

        if (!data.ok) return Swal.fire("Error guardando el juego", "", "info");

        Swal.fire(`${this.typeform.name} almacenado`.toUpperCase(), "", "success");

        this.$emit("closeModa")
      } catch (error) {
        console.log('ERROR CREATE EXTERNAL GAME', error);
      }
    },
  }
}
</script>