var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("externalGames.table.name")))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
          },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("externalGames.table.logo")))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.logo.$error,
          },attrs:{"placeholder":"https://logo.4.my.game/","border":""},model:{value:(_vm.typeform.logo),callback:function ($$v) {_vm.$set(_vm.typeform, "logo", $$v)},expression:"typeform.logo"}}),(_vm.typesubmit && _vm.$v.typeform.logo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.logo.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e(),(_vm.typesubmit && _vm.$v.typeform.logo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.logo.url)?_c('span',[_vm._v(" Este campo debe ser una url. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Product ID")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.productId.$error,
          },attrs:{"placeholder":"Ej: end2end","border":""},model:{value:(_vm.typeform.productId),callback:function ($$v) {_vm.$set(_vm.typeform, "productId", $$v)},expression:"typeform.productId"}}),(_vm.typesubmit && _vm.$v.typeform.productId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.productId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Launch Url")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.launchURL.$error,
            },attrs:{"placeholder":"https://launch.for.game/api","border":""},model:{value:(_vm.typeform.launchURL),callback:function ($$v) {_vm.$set(_vm.typeform, "launchURL", $$v)},expression:"typeform.launchURL"}}),(_vm.typesubmit && _vm.$v.typeform.launchURL.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.launchURL.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Token del proveedor")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.tokenClient.$error,
          },attrs:{"placeholder":"Abdef-gh12-fg23","border":""},model:{value:(_vm.typeform.tokenClient),callback:function ($$v) {_vm.$set(_vm.typeform, "tokenClient", $$v)},expression:"typeform.tokenClient"}}),(_vm.typesubmit && _vm.$v.typeform.tokenClient.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.tokenClient.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])],1),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',[_vm._v("Guardar")]),_c('vs-button',{attrs:{"success":"","type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }